import { defineStore } from 'pinia'
import type { GetOptionsReturnItem } from '@deorderbook/sdk'
import { getOptions } from '@deorderbook/sdk'
import { fund } from '@deorderbook/sdk/ethereum/option'
import { onWalletReady } from '@base/composables/onWalletReady'

export const useOptionsStore = defineStore('options', () => {
  const loading = ref(false)
  const options = ref<GetOptionsReturnItem[]>([])
  const error = ref<Error | null>(null)

  function isOptionStarted(startTimestamp: string) {
    return Number(startTimestamp) * 1000 < new Date().getTime()
  }

  async function actionRefreshOptions(force = false) {
    if (!force && loading.value) return
    loading.value = true
    try {
      const res = await getOptions()
      options.value = res.filter((option) =>
        isOptionStarted(option.startTimestamp),
      ) as GetOptionsReturnItem[]
      error.value = null
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      if (err?.body) {
        error.value = new Error(JSON.parse(err.body).error.message, {
          cause: err,
        })
      } else {
        error.value = new Error(err?.message ?? err, {
          cause: err,
        })
      }
    } finally {
      loading.value = false
    }
  }

  // [ Option Fund ]
  const {
    fetchState: fetchOptionFund,
    refreshAllState: refreshAllOptionFund,
    getCaches: getOptionFundCaches,
  } = useAsyncCache((...args: Parameters<typeof fund>) => {
    return fund(...args)
  }, '')

  onWalletReady(
    () => {
      actionRefreshOptions().then()
    },
    {
      status: 'setup',
    },
  )

  return {
    loading,
    error,
    options,
    actionRefreshOptions,
    fetchOptionFund,
    refreshAllOptionFund,
    getOptionFundCaches,
  }
})
