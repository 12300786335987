import { defineStore } from 'pinia'
import type { GetOptionsReturnItem, Pool } from '@deorderbook/sdk'
import { getSniperPoolList } from '@deorderbook/sdk'
import { Big } from 'big.js'
import { gql } from '@urql/core'
import { useOptionsStore } from '@base/store/options'
import { useSniperNickname } from '@base/composables/useSniperNickname'
import { useTokensStore } from '@base/store/tokens'
import { onWalletReady } from '@base/composables/onWalletReady'
import type { ActivePoolResponse, FormattedPool } from '@base/types/sniperPools'
import { formatPercentage, getPercentageChange } from '@base/utils/number'
import type { HODLType } from '@deorderbook/shared'

const GET_ACTIVE_SNIPER_POOLS_TVL_QUERY = gql`
  query stats_tvl_by_active_pool {
    stats_tvl_by_active_pool(where: { type: { _nlike: "%HODLPool" } }) {
      type
      expiry
      pool_id
      strike
      underlying
      underlying_in_pool
      underlying_in_pool_usd
      underlying_in_pool_usd_24hr_ago
      total_value_locked
      total_value_locked_24hr_ago
      collateral_in_pool
      collateral_in_pool_usd
      collateral_in_pool_usd_24hr_ago
    }
  }
`
/**
 * @description Get pools from the Subgraph and convert to FormattedPool type
 */

export const usePoolsStore = defineStore('pools', () => {
  const loading = ref(false)
  const error = ref<Error | null>(null)
  const sniperPools = ref<Pool[]>([])
  const formattedPools = ref<FormattedPool[]>([])
  const activePoolsTvl = ref<ActivePoolResponse[]>([])
  const { client } = useBackendClient()
  const { options } = toRefs(useOptionsStore())
  const { tokenDOB, tokenUHODL, tokenSellTokenHODL, getSniperPrice } =
    toRefs(useTokensStore())
  const { divDecimals } = useTokens()

  async function actionRefreshPools(force = false) {
    if (!force && loading.value) {
      return
    }
    loading.value = true
    try {
      const [sniperPoolsResponse, activePoolsTvlResponse] = await Promise.all([
        getSniperPoolList(),
        client.value.query(GET_ACTIVE_SNIPER_POOLS_TVL_QUERY, {}).toPromise(),
      ])
      sniperPools.value = sniperPoolsResponse.filter((pool) => pool.isActive)
      activePoolsTvl.value =
        activePoolsTvlResponse?.data?.stats_tvl_by_active_pool ?? []
      error.value = null
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err)
      if (err?.body) {
        error.value = new Error(JSON.parse(err.body).error.message, {
          cause: err,
        })
      } else {
        error.value = new Error(err?.message ?? err, {
          cause: err,
        })
      }
    } finally {
      loading.value = false
    }
  }

  function getOptionByAddress(address: string) {
    return options.value.find((option) => {
      return option.address === address
    })
  }

  function getPoolWithTvl(option: GetOptionsReturnItem) {
    return activePoolsTvl.value.find((poolWithTvl) => {
      return (
        divStrikePrice(option.strikePrice) === poolWithTvl.strike?.toString() && // same strike price (divided)
        new Date(poolWithTvl.expiry ?? 0).getTime() / 1000 ===
          Number(option.exerciseTimestamp) && // same exercise timestamp in seconds
        ((option.optionType === '1' && poolWithTvl.underlying === 'uHODL') ||
          (option.optionType === '0' &&
            poolWithTvl.underlying === 'sellTokenHODL')) // same token type (uSNIPER or sellTokenSNIPER) and underlying (uHODL or bHODL)
      )
    })
  }

  function handlePoolFormatting() {
    const { getDeorderOriginApy } = useApy()
    formattedPools.value = sniperPools.value

      .map((pool: Pool) => {
        const targetOption = getOptionByAddress(pool.option)
        if (
          !targetOption ||
          Number(targetOption.startTimestamp) * 1000 > new Date().getTime()
        ) {
          return null
        }
        const poolWithTvl = getPoolWithTvl(targetOption)
        const apr = getDeorderOriginApy(targetOption).value
        const sniperToken =
          targetOption?.optionType === '0' ? 'sellTokenSNIPER' : 'uSNIPER'
        const hodlToken = (
          targetOption?.optionType === '0' ? 'sellTokenHODL' : 'uHODL'
        ) as HODLType
        const hodlPrice =
          targetOption?.optionType === '0'
            ? tokenSellTokenHODL.value.priceUSD
            : tokenUHODL.value.priceUSD
        const stakedAmount = divDecimals(
          pool.stakedAmount ?? 0,
          hodlToken,
        ).value
        const sniperPrice = getSniperPrice.value(
          sniperToken,
          targetOption?.strikePrice,
          targetOption.sniper,
        ).value?.priceUSD
        const stakedAmountUSD = Big(stakedAmount)
          .mul(sniperPrice ?? hodlPrice)
          .toFixed()
        return {
          id: pool.id,
          type: sniperToken,
          optionType: targetOption?.optionType,
          name: useSniperNickname(options, targetOption) ?? '',
          address: pool.token,
          option: pool.option,
          apr,
          aprString: formatPercentage(apr),
          stakedAmount: pool.stakedAmount,
          stakedAmountUSD,
          rewardPerBlock: pool.rewardPerBlock,
          strikePrice: targetOption?.strikePrice,
          startTimestamp: targetOption?.startTimestamp,
          exerciseTimestamp: targetOption?.exerciseTimestamp,
          totalAmount: pool.totalAmount,
          exerciseAmount: pool.exerciseAmount,
          underlying:
            poolWithTvl?.underlying && poolWithTvl?.underlying_in_pool
              ? {
                  token: poolWithTvl?.underlying as HODLType,
                  value: divDecimals(
                    poolWithTvl?.underlying_in_pool,
                    poolWithTvl?.underlying,
                  ).value,
                }
              : {
                  token: hodlToken,
                  value: stakedAmount,
                },
          tvl: Number(stakedAmountUSD),
          tvlChange:
            poolWithTvl?.total_value_locked &&
            poolWithTvl?.total_value_locked !== 0 &&
            poolWithTvl?.total_value_locked_24h_ago &&
            poolWithTvl?.total_value_locked_24h_ago !== 0
              ? getPercentageChange(
                  poolWithTvl?.total_value_locked_24h_ago || 0,
                  poolWithTvl?.total_value_locked || 0,
                )
              : undefined,
        } as FormattedPool | null
      })
      .filter(
        (pool) =>
          pool !== null &&
          Number(pool.startTimestamp) * 1000 < new Date().getTime(),
      ) as FormattedPool[]
  }

  watch(
    [
      sniperPools,
      tokenDOB,
      tokenSellTokenHODL,
      tokenUHODL,
      options,
      activePoolsTvl,
    ],
    () => {
      if (options.value.length === 0) return
      handlePoolFormatting()
    },
    {
      deep: true,
    },
  )

  onWalletReady(
    () => {
      actionRefreshPools().then()
    },
    {
      status: 'setup',
    },
  )

  return {
    error,
    loading,
    sniperPools,
    formattedPools,
    actionRefreshPools,
  }
})
