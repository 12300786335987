import { Big } from 'big.js'
import { useTokensStore } from '@base/store/tokens'

/**
 * @description Data structure required for sniper APR.
 * @export
 * @interface SniperAPRData
 */
export interface SniperAPRData {
  /** option address */
  address: string
}

const BLOCK_PER_MINUTE = 5
const BLOCK_PER_DAY = 24 * 60 * BLOCK_PER_MINUTE
const BLOCK_PER_YEAR = 365 * BLOCK_PER_DAY

export const useApy = () => {
  const { tokenDOB, tokenSellTokenHODL, tokenUHODL } = toRefs(useTokensStore())
  const { sniperPools } = toRefs(usePoolsStore())
  const { options } = toRefs(useOptionsStore())
  const { divDecimals } = useTokens()

  /**
   * @description get sniper's APY origin value，without %
   * formula: stakedUSD === 0 ? 1000000: rewardUSD / stakedUSD * 100
   * stakedUSD =  stakedAmount * (sellTokenSNIPER ? sellTokenPrice: usdcPrice)
   * rewardUSD = rewardPerBlock * BLOCK_PER_YEAR
   * @return {*}  {Promise<string>}
   */
  function getDeorderOriginApy(data: SniperAPRData, inputTokenUSD?: string) {
    return computed(() => {
      const apyWhenNoStaked = '1000000'
      const sniperPool = sniperPools.value.find(
        (pool) => pool.option === data.address,
      )
      if (!sniperPool) return apyWhenNoStaked
      const targetOption = options.value.find((option) => {
        return option.address === data.address
      })
      if (!targetOption) return apyWhenNoStaked
      const rewardUSD = Big(divDecimals(sniperPool.rewardPerBlock, 'DOB').value)
        .times(BLOCK_PER_YEAR)
        .times(tokenDOB.value.priceUSD)
      const stakedUSD = Big(
        divDecimals(sniperPool.stakedAmount, 'sniper').value,
      )
        .times(
          targetOption.optionType === '0'
            ? tokenSellTokenHODL.value.priceUSD
            : tokenUHODL.value.priceUSD,
        )
        .add(Big(inputTokenUSD ?? '0'))
      // vAPR set to 100,000 when no staked
      const apr = stakedUSD.gt(0)
        ? rewardUSD.div(stakedUSD).mul(100)
        : Big(apyWhenNoStaked)
      return apr.toFixed()
    })
  }

  /**
   * @description Get the APY percentage value of sniper, limiting to two decimal places, with a % symbol.
   * @return {*}  {Promise<string>}
   */
  function getDeorderApy(
    data: SniperAPRData,
    inputTokenUSD?: string,
    asBigNum?: boolean,
  ) {
    return computed(() => {
      const apyNumber = getDeorderOriginApy(data, inputTokenUSD).value
      if (asBigNum) {
        return Big(apyNumber)
      }
      return formatPercentage(apyNumber)
    })
  }

  return {
    getDeorderApy,
    getDeorderOriginApy,
  }
}
